"use client";
import React from "react";
import starB from "@/public/images/starB.svg";
import Image from "next/image";

const Card = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className=" bg-[#252F39] rounded-[15px] py-[60px] px-[20px] flex flex-col items-center justify-center gap-6">
      <div className=" bg-primaryColor border-[3px] border-[#38B2F04D] w-[55px] h-[55px] flex items-center justify-center rounded-full">
        <Image src={starB} alt="starB" />
      </div>
      <p className="text-24 font-bold text-white">{title}</p>
      <p className="text-16 text-textColor font-[300] max-w-[410px] text-center">
        {description}
      </p>
    </div>
  );
};

const index = () => {
  return (
    <div id="advantages" className="container my-20 ">
      <p className="titleHeading">خريطة طريقك للتفوق تبدأ معنا</p>
      <p className=" text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10">
        نرسم لك طريق التفوق من البداية، لتيسير عليك الدراسة وتقديم كافة وسائل
        الدعم المستمر، لضمان نجاحك وتفوقك الدائم.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <Card
          title="محاضرات مجانية"
          description="تتوفر محاضرات مجانية في بعض المواد، لجميع الطلاب المشتركين بالمنصة."
        />
        <Card
          title="المراجعات والمتابعة المستمرة"
          description="تتوفر مراجعات شاملة لكل مادة بعد إنتهاء كل فصل، وتتم المراجعة على مستواك بشكل إحترافي أسبوعياً."
        />
        <Card
          title="الإمتحانات الشاملة والتدريبات"
          description="توفير أفضل إمتحانات مع أكبر بنك للأسئلة المتوقعة في جميع المواد من كل المصادر."
        />
        <Card
          title="مسابقات وجوائز مالية"
          description="تقديم مسابقات تعليمية بشكل مستمر لكل المراحل، مع مكافئات وجوائز مالية للفائزين والأوائل إسبوعياً وشهرياً في كل مادة."
        />
        <Card
          title="أنشطة تعليمية وترفيهية"
          description="تقدم المنصة أنشطة كثيرة مثل المحاضرات والمؤتمرات التعليمية مع أنشطة ترفيهية إضافية، لبناء شخصيتك الناجحة في مختلف المجالات."
        />
        <Card
          title="دعم فني مستمر"
          description="تستطيع التواصل معنا في أي وقت و من أي مكان، للرد على جميع إستفساراتك و حل جميع المشاكل."
        />
      </div>
    </div>
  );
};

export default index;
