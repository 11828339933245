'use client';
import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '@/components/ui/carousel';
import Link from 'next/link';
import CourseCard from '../../shared/CourseCard';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import USER, { SelectCourses } from '@/redux/User';
import MyLink from '@/components/shared/myLink/MyLink';

const Index = ({ courses, loading }: any) => {
	const dispatch = useAppDispatch();
	// const courses = useAppSelector(SelectCourses);
	// const [loading, setLoading] = useState(true);

	// useEffect(() => {
	//   const fetchData = async () => {
	//     await dispatch(
	//       USER.thunks.doGetCourses({ search: "", school_years: [] })
	//     );
	//     setLoading(false);
	//   };
	//   fetchData();
	// }, [dispatch]);

	return (
		<div className='container my-20'>
			<p className='titleHeading'>أحدث المحاضرات المتوفرة</p>
			<p className='text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10'>
				توجد هنا المحاضرات التي تمت إضافتها حديثاً بالمنصة.
			</p>
			{loading ? (
				<SkeletonTheme baseColor='#2F3943' highlightColor='#6b6e72'>
					<div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 pb-10'>
						{Array(3)
							.fill(0)
							.map((_, index) => (
								<div key={index} className='p-5 bg-[#252F39] rounded-[15px]'>
									<Skeleton height={220} className='w-full' />
									<Skeleton height={20} width='70%' className='my-3' />
									<Skeleton height={20} width='90%' />
									<Skeleton height={20} width='50%' className='mt-3' />
									<Skeleton height={30} width='100%' className='mt-5' />
								</div>
							))}
					</div>
				</SkeletonTheme>
			) : courses && courses.length > 0 ? (
				<Carousel
					className=' '
					opts={{
						direction: 'rtl',
					}}>
					<CarouselContent className='pb-10'>
						{[...courses].reverse().map((course: any) => (
							<CarouselItem
								key={course.id}
								className='md:basis-1/2 xl:basis-1/3'>
								<CourseCard course={course} />
							</CarouselItem>
						))}
					</CarouselContent>
					<div className='flex justify-center items-center gap-3'>
						<CarouselPrevious className='bg-[#252F39] border-[#252F39] hover:bg-[#252F39]' />
						<MyLink
							href='/courses'
							className='bg-[#252F39] text-16 text-white font-[400] py-[10px] px-[40px] rounded-[8px]'>
							عرض الكل
						</MyLink>
						<CarouselNext className='bg-[#252F39] border-[#252F39] hover:bg-[#252F39]' />
					</div>
				</Carousel>
			) : (
				<div className='text-center text-red-400 bg-[#2F3943] rounded-[5px] py-5 px-3'>
					<p>لم يتم العثور على دورات.</p>
				</div>
			)}
		</div>
	);
};

export default Index;
